<script>

import DeviceInfosUpdated from "./device-infos-updated";
import {
  devicesMethods,
  alertsMethods,
  devicesComputed,
  alertsComputed,
} from "@/state/helpers";
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Consolidated-Province component
 */

export default {
  props: ["devices", "province", "causes", "actions", "states", "state", "loaderData"],
  mounted() {
  },
  components: {
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    DeviceInfosUpdated,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],
      devicesPositions: [],
      eventsData: [],
      alertsData: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      imageDevice: require("@/assets/images/iconDevice.svg"),

      imageDeviceBlue: require("@/assets/images/pin_blue.svg"),
      imageDeviceCiel: require("@/assets/images/pin_ciel.svg"),
      imageDeviceGreen: require("@/assets/images/pin_green.svg"),
      imageDeviceYellow: require("@/assets/images/pin_yellow.svg"),
      imageDeviceOrange: require("@/assets/images/pin_orange.svg"),
      imageDeviceRed: require("@/assets/images/pin_red.svg"),
      imageDeviceBlack: require("@/assets/images/pin_black.svg"),

      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      loaderDevice: false,

      deviceInfos: {},
      deviceEvents: {},
    };
  },
  computed: {
    ...devicesComputed,
    ...alertsComputed,
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    ...devicesMethods,
    ...alertsMethods,
    
    passDevice(device) {
      this.loaderDevice = true;
      let card = document.getElementById("deviceInfosCard");
      card.style.visibility = "visible";
      let id = device._id ? device._id : device.id
      this.getDeviceInfos(id)
        .then((device) => {
          let params = {
            serial: device.serial,
            limit: 100,
            sortBy: "createdAt:desc",
          };
          this.getEventsBy(params).then(() => {
            this.getAlertsBy({
              serial: device.serial,
              limit: 5,
              sortBy: "createdAt:desc",
            }).then(() => {
              this.loaderDevice = false;
            });
          });
        })
        .catch((error) => {
          this.makeToast("Alert Error", error, "danger");
        });
    },
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    getEvents(newVal) {
      this.eventsData = newVal.results;
    },
    getAlerts(newVal) {
      this.alertsData = newVal.results;
    },
    getDevice(newVal) {
      this.deviceInfos = newVal;
    },
    devices(newVal) {
      this.devicesPositions = newVal;
      let length = newVal.length;
      if (length == 1) {
        const mapComponent = this.$refs.map;
        let start = [newVal[0].lat, newVal[0].lng];
        let end = [newVal[length - 1].lat, newVal[length - 1].lng];
        mapComponent.fitBounds([start, end], { maxZoom: 12 });
      }
    },
    
  },
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loaderData == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">
      <div
        style="
          margin-top: 210px;
          position: absolute;
          z-index: 999;
          width: 30px;
          right: 3%;
        "
      >
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="imageReset"
              @click="setResetZoom"
          /></b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomIn"
              @click="setZoomIn"
          /></b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomOut"
              @click="setZoomOut"
          /></b-col>
        </b-row>
      </div>

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        v-on:update:zoom="updateZoom($event)"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-marker
          :zIndexOffset="100"
          v-for="marker in devicesPositions"
          :key="marker.id"
          :draggable="false"
          :lat-lng="{ lat: marker.lat, lng: marker.lng }"
          :icon="icon"
          @click="
            passDevice(marker)
          "
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-size="iconSize"
            :tooltipAnchor="tooltipAnchor"
            :icon-url="imageDevice"
          >
          <img
              v-if="state == 0"
              :src="imageDeviceBlue"
            />
            <img
              v-if="state == 1"
              :src="imageDeviceCiel"
            />
            <img
              v-if="state == 2"
              :src="imageDeviceGreen"
            />
            <img
              v-if="state == 3"
              :src="imageDeviceYellow"
            />
            <img
              v-if="state == 4"
              :src="imageDeviceOrange"
            />
            <img
              v-if="state == 5"
              :src="imageDeviceRed"
            />
            <img
              v-if="state == 6"
              :src="imageDeviceBlack"
            />
          </l-icon>
          <l-tooltip
            class="rounded text-secondary"
            :options="{
              interactive: 'true',
              direction: 'top',
            }"
          >
            <span style="font-weight: 550"
              >{{ $t("tooltips.name.text") }} :
            </span>
            {{ marker.name }}<br />
            <!--<span style="font-weight: 550"
              >{{ $t("tooltips.stockType.text") }} :
            </span>
             <span
              class="badge font-size-12"
              :class="{
                'badge-danger':
                  `${marker.stockType}` === '24H' ||
                  `${marker.stockType}` === '24 H',
                'badge-warning':
                  `${marker.stockType}` === 'DEMI JOURNEE' ||
                  `${marker.stockType}` === 'Jusqu\'à 20h' ||
                  `${marker.stockType}` === 'jusqu\'à 16H',
                'badge-success':
                  `${marker.stockType}` === 'VIDE',
                'badge-secondary':
                  `${marker.stockType}` === '-' ||
                  `${marker.stockType}` === 'NON COMMUNIQUE',
              }"
            >
              {{ marker.stockType }} </span
            ><br /> -->
            <span style="font-weight: 550">Province : </span>
            {{ marker.province.name }}
          </l-tooltip>
        </l-marker>
      </l-map>
    </div>
    <DeviceInfosUpdated
      v-bind:device="deviceInfos"
      :events="eventsData"
      :alerts="alertsData"
      :causes="causes"
      :actions="actions"
      :states="states"
      :loader="loaderDevice"
    />
  </div>
</template>






<style scoped>
.apex-charts text {
  fill: #000;
}
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
</style>