<script>
import Layout from "../../layouts/main";
import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import ConsolidatedProvince from "./consolidated-province";
import {
  layoutMethods,
  layoutComputed,
  consolidatedMethods,
  statesMethods,
  causesMethods,
  actionsMethods,
  consolidatedComputed,
  statesComputed,
  causesComputed,
  actionsComputed,
} from "@/state/helpers";
/**
 * Consolidated-Province component
 */
export default {
  page: {
    title: "Consolidated-Province",
    meta: [{ name: "Consolidated-Province" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    ConsolidatedProvince,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
    this.retrieveConsolidated();
  },
  data() {
    return {
      title: "Consolidated-Province",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Consolidated-Province",
          active: true,
        },
      ],

      loaderData: true,

      province: null,
      state: null,

      devicesData: [],
      statesData: [],
      causesData: [],
      actionsData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...consolidatedComputed,
    ...statesComputed,
    ...causesComputed,
    ...actionsComputed,
  },
  methods: {
    ...layoutMethods,
    ...consolidatedMethods,
    ...statesMethods,
    ...causesMethods,
    ...actionsMethods,

    retrieveConsolidated() {
      this.getConsolidatedBy().then(async () => {
        this.loaderData = false;
        await this.retrieveStates();
        await this.retrieveCauses();
        await this.retrieveActions();
      });
    },
    retrieveStates() {
      if (this.getStates.results)
        return this.statesData = this.getStates.results;
      else return this.getStatesBy({ limit: 100 }).then((states) => {
          return states;
        });
    },
    retrieveCauses() {
      
        return this.getCausesBy({ limit: 100 }).then((causes) => {
          return causes;
        });
    },
    retrieveActions() {
      if (this.getActions.results) this.actionsData = this.getActions.results;
      else return this.getActionsBy({ limit: 100 }).then((actions) => {
          return actions;
        });
    },
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
  },
  watch: {
    getConsolidated(newVal) {
      let province = this.$route.params.province;
      this.state = this.$route.params.state;

      let offline = [];
      let lessZero = [];
      let zero = [];
      let two = [];
      let eight = [];
      let twelve = [];
      let fourteen = [];

      let keys = Object.keys(newVal);
      keys.map((obj) => {
        if (newVal[obj].province && newVal[obj].province.name == province) {
          if (newVal[obj].event) {
            let now = new Date();
            let dateEvent = new Date(newVal[obj].event.createdAt);
            var diffMs = now.getTime() - dateEvent.getTime();
            if (diffMs > 1000 * 60 * 15) {
              offline.push(newVal[obj]);
            } else {
              let temp = newVal[obj].event.temp;
              if (temp < 0) {
                lessZero.push(newVal[obj]);
              } else if (temp <= 2) {
                zero.push(newVal[obj]);
              } else if (temp <= 8) {
                two.push(newVal[obj]);
              } else if (temp <= 12) {
                eight.push(newVal[obj]);
              } else if (temp <= 14) {
                twelve.push(newVal[obj]);
              } else if (temp > 14) {
                fourteen.push(newVal[obj]);
              }
            }
          } else {
            offline.push(newVal[obj]);
          }
        }
      });
      if (this.state == "0") this.devicesData = lessZero;
      if (this.state == "1") this.devicesData = zero;
      if (this.state == "2") this.devicesData = two;
      if (this.state == "3") this.devicesData = eight;
      if (this.state == "4") this.devicesData = twelve;
      if (this.state == "5") this.devicesData = fourteen;
      if (this.state == "6") this.devicesData = offline;
    },
    getStates(newVal) {
      this.statesData = newVal.results;
    },
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
  },
  beforeDestroy() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" />
    <ConsolidatedProvince
      v-bind:devices="devicesData"
      :province="province"
      :state="state"
      :causes="causesData"
      :actions="actionsData"
      :states="statesData"
      :loaderData="loaderData"
    />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  /*padding-top: 60px;*/
  min-height: 1320px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>